<template>
  <section class="sidebar" v-clickaway="closeSidebar">
    <header class="header">
      <button
        type="button"
        class="icon-button"
        tabindex="0"
        @click="closeSidebar"
      >
        <span class="material-symbols-rounded me-2"
          >keyboard_double_arrow_right</span
        >
      </button>
      <div class="header-content">
        <h5 class="header-title">AI Suggestions for Discussions</h5>
        <p class="header-subtitle">
          Upload a case law file to generate discussion
        </p>
      </div>
    </header>

    <form class="upload-form" @click="showModal = true">
      <label for="fileInput" class="visually-hidden">Upload File</label>
      <div class="upload-button">
        <input
          type="file"
          id="fileInput"
          class="file-input"
          aria-label="Upload File"
          @change="handleFileChange"
        />
        <button type="button" class="button-blue" tabindex="0">
          <span class="material-symbols-rounded me-2">add</span>
          <span class="button-text">Upload File</span>
        </button>
      </div>
    </form>

    <b-modal v-model="showModal" hide-footer hide-header size="lg">
      <!-- Custom modal header with close button -->
      <div
        class="custom-header d-flex justify-content-between align-items-center"
      >
        <h4 class="m-0">Upload File</h4>
        <button @click="showModal = false" class="close-btn">
          <span class="material-symbols-rounded">close</span>
        </button>
      </div>

      <div class="mt-4">
        <FileInput
          :single="true"
          :accept="['pdf']"
          @input-files="$set(files, 0, $event)"
        />
        <FileProcess
          style="max-height: 200px; overflow-y: auto; margin-top: 1rem"
          :accept="['pdf']"
          :files="files[0]"
          @output-files="outputFiles"
          :single="true"
        />
      </div>

      <div v-if="files[1].length" class="mt-2 mb-3">
        <label for="fileName" class="pt mb-2">File Name</label>
        <input
          type="text"
          v-model="selectedFileName"
          id="fileName"
          class="form-control custom_file"
          placeholder="Please rename your file"
        />
      </div>

      <div class="d-flex justify-content-end">
        <button
          class="btn d-flex align-items-center"
          :class="files[1].length ? 'upload-active' : 'upload'"
          @click="handleFileUpload"
        >
          <span class="material-symbols-rounded me-2">upload</span>Upload
        </button>
      </div>
    </b-modal>

    <section class="uploaded-files">
      <hr class="separator" />
      <p class="uploaded-files-title">Uploaded Files</p>
      <hr class="separator" />
      <div class="uploaded-file" v-for="(el, idx) in documents" :key="el.id">
        <p class="file-title">{{ el.case_name }}</p>
        <button
          v-if="typeof el.completedPerCentage === 'string'"
          @click="summarize(el.id)"
          class="summarize-btn"
          type="button"
          tabindex="0"
          aria-label="Summarize content"
        >
          <span>Summarize</span>
        </button>
        <template v-else>
          <div
            tabindex="0"
            aria-label="Summarize content"
            v-if="el.completedPerCentage >= 0 && el.completedPerCentage < 100"
          >
            <span class="summarizing"
              >Summarizing
              <b-spinner
                class="ms-1 me-2"
                variant="primary"
                label="Nexa Thinking"
                style="width: 1.2rem; height: 1.2rem; border-width: 0.125rem"
              ></b-spinner
            ></span>
          </div>

          <div
            v-else
            @click="goToFilePage(el.id)"
            class="summary-button"
            tabindex="0"
            aria-label="View  Summary"
          >
            View Summary
          </div>
        </template>
        <hr v-if="idx !== documents.length - 1" class="separator" />
      </div>
    </section>
  </section>
</template>
<script>
import FileInput from "./../../components/input/FileInput.vue";
import FileProcess from "./../../components/input/FileProcess.vue";
import advice from "../../store/advice";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    discussionId: {
      type: Number,
      required: true,
    },
  },
  components: {
    FileProcess,
    FileInput,
  },
  data() {
    return {
      showModal: false,
      files: [[], []],
      selectedFileName: "",
      uploadedFiles: [],
      documents: [],
      extension: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    selectedFileName(newValue) {
      const baseName = newValue.replace(this.extension, "");
      this.selectedFileName = baseName + this.extension;
    },
  },
  methods: {
    outputFiles(event) {
      this.$set(this.files, 1, event);
      this.extension = `.${event[0].ext}`;
      this.selectedFileName = event[0].name;
    },

    fetchData() {
      advice
        .ViewLegalWriter(this.$route.params.id)
        .then((response) => {
          const DATA = response.data.data;
          this.documents = DATA.discussions[0].documents;

          const shouldRefetch = this.documents.some(
            (doc) =>
              typeof doc.completedPerCentage === "number" &&
              doc.completedPerCentage < 100
          );

          if (shouldRefetch) {
            this.fetchData();
          }
        })
        .catch(() => {
          this.$toast.error("Error fetching data");
        });
    },

    summarize(id) {
      try {
        advice
          .SummarizeDocument(id)
          .then(() => {
            this.fetchData();
          })
          .catch(() => {
            this.$toast.error("Error Summarizing Document");
          });
      } catch (err) {
        this.$toast.error("Error Summarizing Document");
      }
    },

    closeSidebar() {
      this.$emit("closeSidebar");
    },
    goToFilePage(docId) {
      this.$router.push({
        name: "LegalAdvice_Summary",
        params: { id: this.discussionId, docId: docId },
      });
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.files[0] = [file]; // Store file in files array
        this.selectedFileName = file.name;
      } else {
        this.files = [[], []]; // Reset files if no file selected
        this.selectedFileName = "";
      }
    },
    handleFileOutput(outputFiles) {
      this.files[1] = outputFiles; // Store output files if needed
      if (this.files[1].length > 0) {
        this.selectedFileName = this.files[1][0].name; // Set file name
      }
    },
    handleFileUpload() {
      const formData = new FormData();

      // Make sure to use the right array index
      if (this.files[0].length > 0) {
        // Append the actual file (binary)
        formData.append("case_file", this.files[0][0]); // Get the first file from the first array
      } else {
        this.$toast.error("No file selected for upload");
        return;
      }

      formData.append("case_name", this.selectedFileName); // Append the file name

      // Call the upload document function
      advice
        .UploadDocument(this.discussionId, formData)
        .then((response) => {
          this.$toast.success(response.data.message);
          this.showModal = false;
          this.documents = response.data.data.discussions[0].documents;
        })
        .catch((error) => {
          console.error("File upload failed:", error);
        });
    },
  },
};
</script>

<style scoped>
.summary-button {
  margin-top: 4px;
  text-decoration: underline;
  align-self: stretch;
  border: none;
  border-radius: 4px;
  color: #1890ff;
  padding: 2px 8px;
  font: 500 14px/1 Poppins, sans-serif;
  cursor: pointer;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.summary-button:focus {
  outline: 2px solid #1890ff;
  outline-offset: 2px;
}

.summary-button:active {
  background: #d9dcdc;
}

.summarize-btn {
  margin-top: 8px;
  align-self: stretch;
  width: min-content;
  border-radius: 4px;
  border: 1px solid #d1d2d5;
  background: #fff;
  padding: 2px 8px;
  color: #000046;
  font: 500 14px/1 Poppins, sans-serif;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.summarizing {
  margin-top: 8px;
  align-self: stretch;
  width: min-content;
  border-radius: 4px;
  border: 1px solid #d1d2d5;
  background: #fff;
  padding: 2px 8px;
  color: #000046;
  font: 500 14px/1 Poppins, sans-serif;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  gap: 4px;
  align-items: center;
}

.summarize-btn:hover {
  background-color: #f5f5f5;
}

.summarize-btn:focus {
  outline: 2px solid #000046;
  outline-offset: 2px;
}

.summarize-btn:active {
  background-color: #e5e5e5;
}
.form-control {
  font-size: 14px;
}

.sidebar {
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(170, 171, 175, 0.6);
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 24px;
  height: 100vh;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
}

.icon-button {
  color: #0e4485;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: none;
  border: none;
  cursor: pointer;
}

.icon-image {
  width: 32px;
  height: 32px;
}

.header-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: "Poppins", sans-serif;
}

.header-title {
  font-size: 20px;
  color: #383a3e;
  font-weight: 600;
  margin: 0;
}

.header-subtitle {
  font-size: 12px;
  color: #86888d !important;
  font-weight: 400;
  line-height: 24px;
  margin: 4px 0 0;
}

.upload-form {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.upload-button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-input {
  display: none;
}

.button-blue {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #0e4485;
  color: #fff;
  border-radius: 4px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.upload-icon {
  width: 24px;
  height: 24px;
}

.button-text {
  margin: 0;
}

.uploaded-files {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: scroll;
  padding-bottom: 60px;
}

.separator {
  border: 1px solid var(--mid-grey);
}

.uploaded-files-title {
  color: #86888d;
  font-weight: 400;
  margin: 0px;
  padding: 0px 8px;
  font-family: "Poppins", sans-serif;
}

.uploaded-file {
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  padding: 6px 0 6px 6px;

  font-family: "Poppins", sans-serif;
}

.file-title {
  font-size: 14px;
  color: #383a3e;
  margin: 0;
}

.file-description {
  font-size: 12px;
  color: #86888d;
}

.loading-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: none;
  background: none;
  cursor: pointer;
}

.loading-icon {
  width: 18px;
  height: 18px;
  background: #0e4485;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-image {
  width: 18px;
  height: 18px;
}

.loading-text {
  color: #0e4485;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.link-button {
  text-decoration: underline;
  color: #0e4485;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
}

.background-image {
  position: absolute;
  right: 126px;
  top: 109px;
  width: 20px;
  height: 21px;
}

.upload {
  color: #d1d2d5;
  background: #0e44851a;
  width: 118px;
}

.upload-active {
  width: 118px;
  background: #0e4485;
  color: #fff;
}

.upload:hover {
  background: #0e4485;
  color: #fff;
}
</style>
